<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="userList-container">
    <div class="common-Summary-container">
      <div class="Summary-item common-nofinger">
        <div class="item-title">注册用户总数</div>
        <div @click="() => { collect(
                '',
                '注册用户总数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' },
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              )}" class="item-num common-nofinger">{{ totalNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">今日新增注册用户数</div>
        <div @click="() => { collect(
                'todayNum',
                '今日新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' },
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              )}" class="item-num">{{ todayNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">昨日新增注册用户数</div>
        <!-- { name: '所属公司', type: 'select', modelKey: 'companyId', selOpt: rolesCompany }, -->
        <div @click="() => { collect(
                'yesterdayNum',
                '昨日新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' },
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              )}" class="item-num">{{ yesterdayNew || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本周新增注册用户数</div>
        <div @click="() => { collect(
                'weekNum',
                '本周新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' },
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              )}" class="item-num">{{ weekNew || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">本月新增注册用户数</div>
        <div @click="() => { collect(
                'monthNum',
                '本月新增注册用户数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' },
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '账号ID', colProp: ['account'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              )}" class="item-num">{{ monthNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">在线客服数</div>
        <div @click="() => { collect(
                'serviceLoginNum',
                '在线客服数',
                [
                  { name: '用户名称/账号', type: 'input', modelKey: 'name' },
                  { name: '所属公司', type: 'input', modelKey: 'companyName' },
                ],
                [
                  { name: '用户名称', colProp: ['nickName'] },
                  { name: '所属公司', colProp: ['companyName'] }
                ]
              )}" class="item-num">{{ serviceLoginNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">用户调查统计</div>
        <div @click="() => { $router.push({path:'/manage/user-echarts'}) }" class="item-num">{{ questionnaireNum || "0" }}</div>
      </div>
    </div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <!-- <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select
          class="common-screen-input"
          v-model="params.companyId"
          placeholder="请选择"
          @change="companyFun"
          filterable
        >
          <el-option
            v-for="item in rolesCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="common-input-container">
        <span>所属部门:</span>
        <el-cascader
          @change="commonFun"
          clearable
          :props="{
            label: 'name',
            children: 'child',
            value: 'id',
            emitPath: false,
          }"
          class="common-screen-input"
          v-model="params.userDepartId"
          :options="selectDepart"
        ></el-cascader>
      </div>
      <div class="common-input-container">
        <span>员工角色:</span>
        <el-select
          class="common-screen-input"
          v-model="params.roleId"
          placeholder="请选择"
          @change="commonFun"
          filterable
        >
          <el-option
            v-for="item in selectRole"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>员工姓名/账号:</span>
        <el-input
          v-model="params.name"
          class="common-screen-input"
          placeholder="请输入"
        ></el-input>
      </div>
      <el-button class="common-screen-btn" type="primary" @click="() => getTableData()"
        >查 询</el-button
      >
      <el-button class="common-screen-btn" @click="() => resetBtn()" plain
        >重 置</el-button
      >
    </div>
    <el-button class="common-screen-btn" type="primary" @click="addDrawer()"
      >新增员工</el-button
    >
    <div class="common-upload-container" style="margin-top: 0">
      <el-button
        class="common-screen-btn"
        :loading="this.$vuex.getters.btnLoading"
        type="primary"
        >上传员工表格</el-button
      >
      <input
        v-if="!this.$vuex.getters.btnLoading"
        class="common-upload-input"
        @input="
          (e) => {
            uploadUserXslx(e);
          }
        "
        type="file"
      />
    </div>
    <!-- <el-button class="common-screen-btn" type="primary">上传员工表格</el-button> -->
    <el-button type="text" @click="() => download()">下载员工模板</el-button>
    <el-table class="common-table" :data="tableData">
      <el-table-column
        prop="nickName"
        label="用户名称"
        :formatter="tableColumn"
        width="120px"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="() => getPersDeatil(scope.row)"
            >{{ scope.row.nickName || "--" }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="account"
        label="账号ID"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="roleName"
        label="角色"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="userDepartName"
        label="所属部门"
        :formatter="tableColumn"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="公司名称"
        :formatter="tableColumn"
        width="170px"
      ></el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="birth_day"
        label="生日"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="address"
        label="地址"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="bank_card"
        label="银行卡号"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
        width="200px"
      ></el-table-column>
      <el-table-column label="操作" width="170px" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => editPassword(scope.row.id)"
            >修改密码</el-button
          >
          <!-- <el-popconfirm
            placement="top"
            title="确定删除吗？"
            @confirm="() => deleteUserItem(scope.row.id)"
          >
            <el-button type="text" size="small" slot="reference">删除</el-button>
          </el-popconfirm> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <el-dialog
      v-dialogDrag
      title="修改密码"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="新密码" prop="newPassword">
            <el-input
              class="dialog-input"
              v-model="ruleForm.newPassword"
              maxLength="50"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitForm('ruleForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <user-add
      ref="addUser"
      :drawer="drawer"
      :rolesCompany="rolesCompany"
      :selectRole="selectRole"
      :tableRow="tableRow"
      @handleClose="handleClose"
      @addUserInfo="addUserInfo"
    ></user-add>
    <quit-delete
      ref="quitDialog"
      :quitVisible="quitVisible"
      @handleClose="handleClose"
      @Refresh="Refresh"
    ></quit-delete>
    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="userSumList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>

    <!-- 详情弹窗 -->
    <user-details :drawer="userDrawer" :selectRole="selectRole" :selectDepart="selectDepart" ref="companyDetail" @handleClose="handleClose2"></user-details>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import UserAdd from "./children/userListAdd.vue";
import { Config } from "../../utils/index.js";
import { tableColumn } from "../../utils/index.js";
import {
  getUserList,
  updatePassword,
  addUser,
  updateUserInfo,
  deleteUserItem,
  uploadUserXslx,
  userSumList
} from "../../service/manage.js";
import { commonAllRole, commonDepartAll } from "../../service/common.js";
import QuitDelete from "./children/quitDelete.vue";
import CommonSumDialog from "../../components/common/commonSumDialog"
import userDetails from "./children/userDetails.vue"
import { mapState } from "vuex";

export default {
  components: {
    QuitDelete,
    Breadcrumb,
    UserAdd,
    CommonSumDialog,
    userDetails
  },
  data() {
    return {
      tableColumn,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "员工列表", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        // companyId: null,
        // roleId: null,
        // userDepartId: null,
      },
      centerDialogVisible: false, // 修改密码员工弹窗
      quitVisible: false, // 员工离职弹窗
      ruleForm: {
        // 修改密码
        newPassword: "",
        id: "", // 用户id
      },
      rules: {
        newPassword: [{ required: true, message: "请填写密码", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      tableRow: "",
      selectRole: [], // 角色下拉
      selectDepart: [], // 部门下拉
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
      tableData: [],
      totalNum: 0,
      todayNum: 0,
      yesterdayNew: 0,
      weekNew: 0,
      monthNum: 0,
      serviceLoginNum: 0,//	用户调查统计
      questionnaireNum: 0,//客服在线数
      // 汇总弹窗所需数据
      commonSumVisible: false,
      userSumList, // 列表接口方法
    };
  },
  created() {
    this.getComId()

    // this.commonAllRole();
    // this.getTableData();
  },
  computed: {
    ...mapState(["getUserInfo","comId"])
  },
  methods: {
    // 获取comID&&请求列表
    getComId() {
      setTimeout(()=>{
        if(this.comId){
          this.params.companyId = this.comId
        }else{
          this.params.companyId = this.getUserInfo.roles_company.filter((item) => { return item.id != 0; })[0].id
        }
        this.commonDepartAll(this.params.companyId);
        this.commonAllRole();
        this.getTableData();
      },400)
    },
    // 数据汇总弹窗-start
    sumVisibleClose () {
      this.commonSumVisible = false
    },
    collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId);
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗 -end

    quitDelete(row) {
      this.$refs["quitDialog"].getParams(row);
      this.quitVisible = true;
    },
    async uploadUserXslx(e) {
      // 上传员工表格
      this.$vuex.commit("btnLoading", true);
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持、xls、xlsx格式文件");
        e.target.value = "";
        return;
      }
      let res = await uploadUserXslx({ excelFile: file })
      e.target.value = "";
      if (res.code == 200) {
        this.getTableData();
        this.$message.success("上传成功");
      }
    },
    download() {
      // 下载员工模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E5%91%98%E5%B7%A5%E8%A1%A8.xlsx";
    },
    async deleteUserItem(id) {
      // 删除指定用户
      await deleteUserItem({}, id);
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.$message.success("删除成功");
      this.getTableData();
    },
    // 详情
    async getPersDeatil(row) {
      this.$refs.companyDetail.getDataInfo(row)
      this.userDrawer = true
    },
    companyFun(id) {
      //选择公司筛选
      this.params.userDepartId = [];
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getTableData();
      this.commonDepartAll(id);
      this.commonAllRole()
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.getTableData();
    },
    cascaderFun() {
      // 选择部门
      let params = { ...this.params };
      if (!params.companyId) {
        this.$message.error("请先选择所属公司");
        this.params.userDepartId = [];
        // delete params.userDepartId;
        // this.params = params;
      }
    },
    async commonDepartAll(id) {
      // 部门 下拉
      let resData = (await commonDepartAll({}, id)).data;
      this.selectDepart = resData ? resData : [];
    },
    async commonAllRole() {
      // 角色下拉
      let params = {...this.params}
      let resData = (await commonAllRole({companyId: params.companyId})).data;
      this.selectRole = resData;
    },
    async addUserInfo(ruleFormAdd) {
      // 新增用户--子组件掉的方法
      let ruleFormParams = { ...ruleFormAdd };
      let tableId = this.tableRow.id;
      if (this.tableRow) {
        // delete ruleFormParams.passWord
        // delete ruleFormParams.oldPassword
        let res = await updateUserInfo(ruleFormParams, tableId);
        if (res.code == 200) {
          this.$message.success("修改成功");
        }
      } else {
        let res = await addUser(ruleFormParams);
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.params.pageNum = 1;
          this.params.pageSize = 10;
        }
      }
      this.getTableData();
      this.drawer = false;
    },
    resetBtn() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        name: "",
        companyId: this.rolesCompany[0].id
      };
      this.commonDepartAll(this.rolesCompany[0].id);
      this.getTableData();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.getTableData();
    },
    async getTableData() {
      // 获取表格数据
      this.loading = true;
      let params = { ...this.params };
      if (typeof params.userDepartId == "object") {
        delete params.userDepartId;
      }

      let resData = (await getUserList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.todayNum = resData.today;
      this.totalNum = resData.total;
      this.monthNum = resData.month;
      this.yesterdayNew = resData.yesterday;
      this.weekNew = resData.week;
      this.questionnaireNum = resData.questionnaireNum
      this.serviceLoginNum = resData.serviceLoginNum
      this.tableData = resData.records;
      this.loading = false;
    },
    editPassword(e) {
      // 修改密码弹窗

      this.ruleForm.id = e;
      this.centerDialogVisible = true;
    },
    submitForm(formName) {
      // 修改密码确认
      this.$refs[formName].validate((valid) => {
        if (valid) {
          updatePassword(this.ruleForm, this.ruleForm.id);
          this.centerDialogVisible = false;
          this.getTableData();
        } else {
          return false;
        }
      });
    },
    handleClose() {
      this.drawer = false;
      this.quitVisible = false;
      // 关闭新增员工弹窗
      // done();
    },
    Refresh() {
      this.getTableData();
      this.quitVisible = false;
    },
    addDrawer(row) {
      //新增/编辑员工
      this.drawer = true;
      if (row) {
        this.tableRow = row;
        this.$refs.addUser.getUserInfo(row);
      } else {
        this.tableRow = "";
        this.$refs.addUser.clearForm();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.userList-container {
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
</style>
