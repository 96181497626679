import fetch from "./xhr/index";

/**
 * 服务商注册接口
 * @param
 */
export const sign = param => fetch("POST", "/admin/registerService", param);

/**
 * 服务商注册手机验证码生成
 * @param
 */
export const sms = param => fetch("GET", "/ums/umsPhoneCode/phoneCode", param);

/**
 * 创建公司
 * @param
 */
export const found = param => fetch("POST", "/company/found", param);


/**
* 个人详细信息
* @param
*/
export const companyInfo = param => fetch("POST", "/admin/companyInfo", param);

/**
* 服务商个人资料修改
* @param
*/
export const personUpdate = param => fetch("POST", "/admin/update", param);

/**
*员工列表
*@param
*/
export const list = param => fetch("POST", "/admin/list", param);

/**
*员工列表详情修改
*@param
*/
export const listUpdate = param => fetch("GET", "/admin/list/update", param);

/**
*员工详情修改记录
*@param
*/
export const edit = param => fetch("GET", "/ums/umsAdminUpdateRecord/list", param);

/**
*员工列表详情
*@param
*/
export const basic = param => fetch("GET", "/admin/list/basic", param);
