<template>
  <div class="quitDelete-container">
    <el-dialog
      v-dialogDrag
      title="员工离职"
      :append-to-body="true"
      :visible.sync="quitVisible"
      width="50%"
      :before-close="handleClose"
    >
      <div class="userInfo">
        <img class="userInfo-img" v-if="userInfo.icon" :src="userInfo.icon" />
        <span class="user-icon" v-else
          ><i class="el-icon-user iconPeople"></i
        ></span>
        <div>{{ userInfo.nickName || "--" }}</div>
      </div>
      <el-row>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-col :span="22">
            <el-col :span="12">
              <el-form-item label="预计离职日期" prop="quitTime">
                <el-date-picker
                  class="common-screen-input_100"
                  v-model="ruleForm.quitTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="离职原因" prop="quitReason">
                <el-select
                  class="common-screen-input_100"
                  v-model="ruleForm.quitReason"
                  multiple
                  filterable
                  placeholder="请选择离职原因（多选）"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="22">
            <el-form-item label="离职备注">
              <el-input
                type="textarea"
                class="common-screen-input_100"
                placeholder="请输入备注"
                v-model="ruleForm.note"
                :autosize="{ minRows: 6 }"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="22">
            <el-form-item label="" prop="oldPassword">
              <el-checkbox v-model="ruleForm.checked"
                >该离职员工不计入人事统计 <i class="el-icon-warning"></i
              ></el-checkbox>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label-width="0">
              <div class="btn">
                <el-button
                  class="common-screen-btn"
                  type="primary"
                  @click="() => submitForm('ruleForm')"
                  >确认离职并删除</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import { quitCommmit } from "../../../service/manage.js";
export default {
  props: ["quitVisible"],
  data() {
    return {
      ruleForm: {
        quitReason: []
      },
      rules: {
        quitReason: [
          { required: true, message: "请选择离职原因", trigger: "change" }
        ],
        quitTime: [
          { required: true, message: "请选择离职时间", trigger: "change" }
        ]
      },
      userInfo: {},
      options: [
        { lable: "个人原因", value: "个人原因" },
        { lable: "家庭原因", value: "家庭原因" },
        { lable: "发展原因", value: "发展原因" },
        { lable: "合同到期不续签", value: "合同到期不续签" },
        { lable: "其他", value: "其他" }
      ]
    };
  },
  methods: {
    submitForm(formName) {
      // 确认离职并删除
      let ruleForm = { ...this.ruleForm };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (ruleForm.quitReason && ruleForm.quitReason.length) {
            ruleForm.quitReason = ruleForm.quitReason.join("; ");
          }
          await quitCommmit(ruleForm);
          this.$emit("Refresh");
          this.$message.success("离职成功");
        } else {
          return false;
        }
      });
    },
    getParams(row) {
      // 父组件传值
      console.log(row);
      this.ruleForm = {
        adminId: row.id,
        quitReason: []
      };
      this.userInfo = {
        icon: row.icon || "",
        nickName: row.nickName
      };
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
    },
    handleClose() {
      this.$emit("handleClose");
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-checkbox__label {
  color: #999;
}
.userInfo {
  text-align: center;
  .userInfo-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .iconPeople {
    width: 100%;
    height: 100%;
    color: #fff;
    background: #87d068;
    border-radius: 50%;
    font-size: 70px;
    line-height: 100px;
  }
  .user-icon {
    display: block;
    width: 100px;
    height: 100px;
    margin: auto;
  }
  div {
    font-size: 18px;
    padding: 16px 0;
  }
}
.quitDelete-container {
  text-align: left;
}
.demo-ruleForm {
  .btn {
    text-align: center;
    padding: 50px 0;
  }
}
</style>
